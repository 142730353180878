// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/shbg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "p[data-v-56b5a5bc]{margin:.00667rem}.bg[data-v-56b5a5bc]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:100%;overflow-y:auto;padding:.02667rem;box-sizing:border-box;overflow-x:hidden;background-size:100% 100%}.top[data-v-56b5a5bc]{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around}.top div[data-v-56b5a5bc]{padding:.01333rem .02667rem;border-radius:.00667rem .00667rem 0 0}.heng[data-v-56b5a5bc]{width:120%;height:.00133rem;background:#999;position:relative;top:-.00267rem;left:-.02667rem;z-index:1}.dian[data-v-56b5a5bc]{z-index:2;background:#183359;border:.00133rem solid #999!important;border-bottom:none!important;color:#05a5ef}", ""]);
// Exports
module.exports = exports;
