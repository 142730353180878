
<template>
<div class="bg">
<div class="top">
    <div v-for="(item,index) in list" :key="index" @click="i=index" :class="i==index?'dian':''">
{{ item }}
    </div>
</div>
<div class="heng"></div>
<ul>
    <li v-for="(item,index) in msglist" :key="index" style="background-color: rgba(0,0,0,0.2);padding:5px;box-sizing: border-box;margin: 10px 0">
    <div>
        <p style="font-size: 18px;">
            {{ item.title }}
        </p>
        <p style="font-size: 14px;font-style:oblique;">
            更新时间:{{ item.time }}
        </p>
    </div>
    </li>
</ul>
</div>
</template>

<script>
export default {
  name: '',
  components: {},
  data () {
    return {
        i:0,
        list:[
            '农气周报','农气月报','农气月报'
        ],
        msglist:[
            {
                title:'2023年6月30日邮郸市农气周报.doc',
                time:' 2023-06-14 14:20:30'
            },
            {
                title:'2023年6月30日邮郸市农气周报.doc',
                time:' 2023-06-14 14:20:30'
            },
            {
                title:'2023年6月30日邮郸市农气周报.doc',
                time:' 2023-06-14 14:20:30'
            },
            {
                title:'2023年6月30日邮郸市农气周报.doc',
                time:' 2023-06-14 14:20:30'
            },
        ]
    }
  },
  created () { },
  mounted () { },
  methods: { },
  computed: {}
}
</script>
<style scoped lang='scss'>
p{
    margin: 5px;
}
.bg{
    background-image: url('~@/assets/images/shbg.jpg');
    width: 100%;
    height:100%;
    overflow-y: auto;
    padding:20px;
    box-sizing: border-box;
    overflow-x:hidden ;
    background-size:100% 100% ;
}
.top{
    display: flex;
    justify-content: space-around;
    div{
        padding:10px 20px;
        border-radius:5px 5px 0 0 ;
    }
}
.heng{
    width:120%;
    height:1px;
    background: #999;
    position: relative;
    top:-2px;
    left: -20px;
    z-index:1;
}
.dian{
    z-index:2;
    background: rgb(24,51,89);
    border: 1px solid #999 !important;
    border-bottom: none !important;
    color:rgb(5,165,239 )
}
</style>
